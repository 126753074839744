import React from 'react';

export const UnauthorizedSVG = () => (
	<svg
		viewBox="0 0 565 267"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		height="100%"
		width="100%"
		preserveAspectRatio="xMidYMid meet"
		data-testid="unauthorized-svg"
	>
		<g clipPath="url(#clip0_1375_267)">
			<rect
				width="563.88"
				height="265.81"
				transform="translate(0.870117 0.509766)"
				fill="white"
				fillOpacity="0.01"
			/>
			<path d="M150.05 257.529H143.87L144.52 254.379H150.7L150.05 257.529Z" fill="#DFE1E5" />
			<path
				d="M181.06 257.531C183.03 257.531 185 257.531 186.97 257.531C186.92 256.481 186.88 255.431 186.83 254.381C185.02 253.691 182.85 253.301 181.08 254.271C181.02 254.301 180.96 254.341 180.9 254.381C180.95 255.431 181.01 256.481 181.06 257.531Z"
				fill="#DFE1E5"
			/>
			<path
				d="M141.99 98.8398C110.62 98.8398 85.1801 124.27 85.1801 155.65V206.74C85.1801 238.11 59.7501 263.55 28.3701 263.55H0.870117"
				stroke="url(#paint0_linear_1375_267)"
				strokeWidth="4.4681"
				strokeMiterlimit="10"
			/>
			<path
				d="M420.16 124.399L418.27 121.929L417.53 116.109L417.4 112.789H419.42L421.66 115.439C422.18 116.059 422.62 116.749 422.94 117.499C423.39 118.539 423.63 119.669 423.63 120.809V123.189L420.16 124.399Z"
				fill="#D4B696"
			/>
			<path
				d="M160.63 111.87L159.06 105.6C159.06 105.21 159.37 104.88 159.76 104.86C160.06 105.13 160.4 105.37 160.63 105.26C160.82 105.17 160.76 104.91 160.97 104.78C161.34 104.55 162.03 105.03 162.24 105.18C162.54 105.39 162.74 105.61 162.86 105.76L163.81 106.1C164.14 106.22 164.39 106.5 164.48 106.85L165.67 107.74C166.21 107.82 166.43 110 166.45 110.07L166.04 112.86C166.07 112.97 166.07 113.09 166.05 113.21C165.96 113.65 165.5 113.91 165.07 113.75L161.09 112.32C160.87 112.25 160.7 112.08 160.63 111.87Z"
				fill="#A27F68"
			/>
			<path
				d="M165.73 116.919C166.92 116.499 169.78 120.329 166.77 112.969C166.59 112.519 166.22 110.519 164.39 109.389C162.47 108.209 160.92 109.649 159.28 108.319C158.39 107.599 158.37 106.789 157.23 106.319C156.44 105.989 155.38 105.939 155.19 106.319C154.97 106.749 156.03 107.399 157.51 109.269C158.97 111.119 158.65 111.409 159.96 112.799C160.5 113.369 160.89 113.669 162.06 114.909C162.67 115.559 162.72 115.629 162.97 115.829C163.52 116.269 164.77 117.259 165.73 116.919Z"
				fill="#A27F68"
			/>
			<path
				d="M164.84 175.06C162.1 173.45 162.32 169.37 162.74 163.63C163.81 148.91 166.33 143.51 166.66 135.95C166.88 130.9 166.17 123.59 161.64 114.5C163.73 114.28 165.81 114.06 167.9 113.83C171.82 123.37 173.58 131.57 174.46 137.43C175.59 144.99 175.69 151.82 175.7 154.41C175.73 165.07 174.28 169.57 175.37 173.41C175.42 173.6 175.65 174.22 175.34 174.79C174.3 176.7 167.91 176.86 164.84 175.06Z"
				fill="#403294"
			/>
			<path
				opacity="0.25"
				d="M2.76012 266.319H206.4C207.44 266.319 208.29 265.469 208.29 264.429C208.29 263.389 207.44 262.539 206.4 262.539H2.76012C1.72012 262.539 0.870117 263.389 0.870117 264.429C0.870117 265.469 1.72012 266.319 2.76012 266.319Z"
				fill="url(#paint1_linear_1375_267)"
			/>
			<path
				opacity="0.25"
				d="M562.86 266.319H359.22C358.18 266.319 357.33 265.469 357.33 264.429C357.33 263.389 358.18 262.539 359.22 262.539H562.86C563.9 262.539 564.75 263.389 564.75 264.429C564.75 265.469 563.9 266.319 562.86 266.319Z"
				fill="url(#paint2_linear_1375_267)"
			/>
			<path
				d="M423.62 98.8398C454.99 98.8398 480.43 124.27 480.43 155.65V206.74C480.43 238.11 505.86 263.55 537.24 263.55H564.75"
				stroke="url(#paint3_linear_1375_267)"
				strokeWidth="4.4681"
				strokeMiterlimit="10"
			/>
			<path
				d="M133.69 107.8C131.9 107.8 130.45 106.35 130.45 104.56V93.1502C130.45 91.3602 131.9 89.9102 133.69 89.9102C135.48 89.9102 136.93 91.3602 136.93 93.1502V104.57C136.92 106.35 135.48 107.8 133.69 107.8Z"
				fill="#B3BAC5"
			/>
			<path
				d="M140.16 107.8C138.37 107.8 136.92 106.35 136.92 104.56V93.1502C136.92 91.3602 138.37 89.9102 140.16 89.9102C141.95 89.9102 143.4 91.3602 143.4 93.1502V104.57C143.4 106.35 141.95 107.8 140.16 107.8Z"
				fill="#B3BAC5"
			/>
			<path
				d="M425.01 107.8C423.22 107.8 421.77 106.35 421.77 104.56V93.1502C421.77 91.3602 423.22 89.9102 425.01 89.9102C426.8 89.9102 428.25 91.3602 428.25 93.1502V104.57C428.25 106.35 426.8 107.8 425.01 107.8Z"
				fill="#0052CC"
			/>
			<path
				d="M431.48 107.8C429.69 107.8 428.24 106.35 428.24 104.56V93.1502C428.24 91.3602 429.69 89.9102 431.48 89.9102C433.27 89.9102 434.72 91.3602 434.72 93.1502V104.57C434.72 106.35 433.27 107.8 431.48 107.8Z"
				fill="#0052CC"
			/>
			<path
				opacity="0.38"
				d="M285 195.54C338.307 195.54 381.52 152.327 381.52 99.02C381.52 45.7135 338.307 2.5 285 2.5C231.694 2.5 188.48 45.7135 188.48 99.02C188.48 152.327 231.694 195.54 285 195.54Z"
				fill="url(#paint4_radial_1375_267)"
			/>
			<path
				d="M346.07 140.879H381.57C404.79 140.879 423.62 122.059 423.62 98.8293C423.62 75.6093 404.8 56.7793 381.57 56.7793H346.07V140.879Z"
				fill="url(#paint5_linear_1375_267)"
			/>
			<path d="M237.09 123.26H276.41V116.82H237.09V123.26Z" fill="url(#paint6_linear_1375_267)" />
			<path
				d="M237.09 78.2291H276.41V71.7891H237.09V78.2291Z"
				fill="url(#paint7_linear_1375_267)"
			/>
			<path
				d="M325.86 146.589H340.11C343.4 146.589 346.07 143.919 346.07 140.629V57.4092C346.07 54.1192 343.4 51.4492 340.11 51.4492H325.86V146.589Z"
				fill="url(#paint8_linear_1375_267)"
			/>
			<path
				d="M219.54 140.879H184.04C160.82 140.879 141.99 122.059 141.99 98.8293C141.99 75.6093 160.81 56.7793 184.04 56.7793H219.54V140.879Z"
				fill="url(#paint9_linear_1375_267)"
			/>
			<path
				d="M239.76 146.589H225.51C222.22 146.589 219.55 143.919 219.55 140.629V57.4092C219.55 54.1192 222.22 51.4492 225.51 51.4492H239.76V146.589Z"
				fill="url(#paint10_linear_1375_267)"
			/>
			<path
				d="M379.21 146.581C378.91 146.001 378.4 145.181 377.56 144.401C376.28 143.221 374.91 142.761 374.18 142.581C374 142.441 373.77 142.281 373.48 142.131C373.23 142.001 372.97 141.901 372.7 141.831L370.63 141.251C370.29 141.161 370.07 140.831 370.11 140.481C370.15 140.091 370.51 139.801 370.9 139.851L379.42 140.801C380.47 140.921 381.46 140.291 381.79 139.291L382.01 138.651C382.14 138.281 382.5 138.041 382.9 138.091C383.31 138.141 383.62 138.471 383.64 138.871L383.72 140.251C383.86 142.671 383.35 145.091 382.26 147.251L379.21 146.581Z"
				fill="#D4B696"
			/>
			<path
				d="M428.29 185.99C429.39 188.07 430.46 190.28 431.47 192.61C435.75 202.44 437.91 211.78 438.95 219.77C440.03 222.59 441.07 225.54 442.05 228.64C444.86 237.5 446.64 245.84 447.75 253.4C446.13 253.87 444.51 254.34 442.88 254.81C441.2 250.25 438.53 243.08 435.09 234.23C430.76 223.08 428.59 217.5 428.5 217.3C422.43 203.22 419.13 199.32 420.72 193.98C421.29 192.14 422.84 188.93 428.29 185.99Z"
				fill="#0065FF"
			/>
			<path
				d="M412.72 189.539C409.67 199.159 406.62 208.789 403.57 218.409C400.84 230.159 398.1 241.909 395.37 253.659C398.3 254.449 397.22 254.159 400.15 254.939C403.08 247.829 405.94 240.299 408.66 232.359C410.04 228.319 411.33 224.339 412.52 220.439C414.74 216.039 417.2 213.029 419.05 211.089C423.68 206.239 427.59 204.829 430.28 199.479C432.31 195.449 431.64 193.159 431.46 192.609C431.32 192.189 430.95 191.419 430.21 189.889C429.46 188.319 428.81 187.039 428.38 186.189C426.55 186.889 424.48 187.559 422.18 188.119C418.67 188.989 415.46 189.379 412.72 189.539Z"
				fill="url(#paint11_linear_1375_267)"
			/>
			<path
				d="M396.28 246.85C396.21 248.32 395.94 250.38 395.01 252.65C394.37 254.22 392.97 257.51 389.49 259.78C387.45 261.11 385.46 261.59 384.18 261.79C384.26 262.22 384.24 262.13 384.32 262.57C386.73 262.62 391.82 262.66 394.24 262.71C394.59 261.89 395.4 261.57 395.92 261.8C396.41 262.03 396.5 262.7 396.5 262.75C397.32 262.68 398.14 262.61 398.96 262.54C399.23 261.52 399.51 260.49 399.8 259.45C401 255.15 402.31 251.02 403.69 247.08C401.21 247.01 398.74 246.93 396.28 246.85Z"
				fill="#344563"
			/>
			<path
				d="M440.03 247.221C440.92 249.251 441.84 252.641 441.92 253.771C442.06 255.691 442.18 257.271 441.21 258.681C440.55 259.641 439.65 260.141 438.15 260.971C436.98 261.621 435.94 262.021 435.21 262.261C435.3 262.651 435.28 262.571 435.37 262.961C437.57 262.901 439.76 262.831 441.96 262.771C442.52 262.751 443.09 262.731 443.65 262.711C443.66 262.661 443.77 262.191 444.22 261.991C444.67 261.791 445.08 262.031 445.17 262.081C445.49 262.261 445.62 262.541 445.67 262.651C446.19 262.691 446.78 262.721 447.42 262.691C448.09 262.671 448.7 262.601 449.24 262.521C449.09 261.461 448.94 260.401 448.78 259.341C448.69 258.741 448.53 257.681 448.29 256.131C447.73 252.481 447 247.951 446.83 246.881C444.56 247.001 442.29 247.111 440.03 247.221Z"
				fill="#344563"
			/>
			<path
				d="M404.04 166.88C412.13 163.96 417.86 161.43 418.22 161.14C418.55 160.87 418.88 160.59 418.88 160.59C418.98 160.51 419.08 160.42 419.19 160.32C421.82 157.99 427.78 151.82 427.76 142.35C427.73 131.65 420.19 125.19 419.11 124.29C420.82 123.5 422.52 122.71 424.23 121.92C426.36 123.97 432.76 130.64 433.79 141.01C434.72 150.27 430.85 157.43 429.11 160.66C427.36 163.91 425.44 166.35 424.02 167.97C423.92 170.1 424 172.75 424.56 175.73C425.49 180.68 427.37 184.49 428.86 186.96C423.1 188.75 417.33 190.54 411.57 192.33C413.04 189.13 412.78 187.11 412.25 185.86C411.07 183.05 407.94 182.53 406.78 179.13C406.31 177.76 406.36 176.51 406.46 175.69C404.27 175.89 395.01 176.46 386.94 170C375.33 160.7 377 145.5 377.12 144.57C379.19 145.06 381.27 145.55 383.34 146.04C383.04 147.74 383.3 152.66 384.35 155.65C387.27 163.99 396.59 169.11 404.04 166.88Z"
				fill="url(#paint12_linear_1375_267)"
			/>
			<path
				d="M406.52 152.01C406.67 152.06 406.9 152.11 407.25 152.17C408.21 152.33 408.84 152.57 409.24 152.86C410.29 153.59 409.6 155.01 410.51 157.07C410.6 157.28 410.88 157.87 411.03 158.72C411.1 159.15 411.12 159.47 411.12 159.54C411.15 160.21 411.58 161.58 413.11 164.55C412.78 164.46 412.46 164.37 412.13 164.28C411.17 163.91 410.2 163.54 409.24 163.17C407.16 161.78 406.7 161.57 406.7 160.58C406.69 159.4 406.68 157.33 406.62 157.25C405.95 156.6 405.08 156.48 405.74 155.68C406.12 155.23 406.41 154.9 406.51 154.81C406.82 154.53 407.04 153.83 406.52 152.01Z"
				fill="#D4B696"
			/>
			<path
				d="M409.17 152.81C408.97 155.53 409.02 159.86 410.76 164.7C410.8 164.82 410.85 164.95 410.9 165.07C412.74 169.95 418.57 172.14 423.04 169.45C423.19 169.36 423.35 169.26 423.5 169.16C423.53 169.14 423.55 169.12 423.58 169.11C426.31 167.24 426.68 163.37 424.47 160.91C422.98 159.26 422 157.68 421.33 156.36C419.26 152.28 419.55 149.41 416.8 147.81C414.71 146.59 412.02 146.79 411.13 146.85C409.41 146.98 408.61 147.42 408.23 147.7C407.34 148.37 406.86 149.14 406.64 149.56C406.11 150.55 405.98 151.47 405.94 152C406.33 152.02 406.78 152.08 407.26 152.18C408.02 152.34 408.67 152.58 409.17 152.81Z"
				fill="#172B4D"
			/>
			<path
				d="M298 24.3503L293.82 0.940321C293.72 0.390321 292.95 0.360321 292.8 0.890321L286.8 21.9503L281.93 20.5603C281.56 20.4503 281.2 20.7703 281.27 21.1503L285.45 44.5603C285.55 45.1103 286.32 45.1403 286.47 44.6103L292.47 23.5503L297.34 24.9403C297.71 25.0603 298.07 24.7303 298 24.3503Z"
				fill="url(#paint13_linear_1375_267)"
			/>
			<path
				d="M271.23 34.4501L262.63 26.2801C262.45 26.1101 262.17 26.3001 262.26 26.5301L266.06 36.7401L263.69 37.6201C263.53 37.6801 263.49 37.8801 263.61 38.0001L272.21 46.1701C272.39 46.3401 272.67 46.1501 272.58 45.9201L268.78 35.7101L271.15 34.8301C271.31 34.7801 271.35 34.5701 271.23 34.4501Z"
				fill="url(#paint14_linear_1375_267)"
			/>
			<path
				d="M252.19 156.739L248.63 180.259C248.55 180.809 249.26 181.089 249.58 180.639L262.02 162.619L266.18 165.499C266.5 165.719 266.94 165.529 266.99 165.149L270.55 141.629C270.63 141.079 269.92 140.799 269.6 141.249L257.16 159.269L253 156.389C252.69 156.169 252.25 156.359 252.19 156.739Z"
				fill="url(#paint15_linear_1375_267)"
			/>
			<path
				d="M280.79 160.989L286.31 171.489C286.42 171.709 286.75 171.619 286.74 171.379L286.42 160.489L288.95 160.409C289.12 160.399 289.23 160.219 289.15 160.069L283.63 149.569C283.52 149.349 283.19 149.439 283.2 149.679L283.52 160.569L280.99 160.639C280.82 160.659 280.71 160.839 280.79 160.989Z"
				fill="url(#paint16_linear_1375_267)"
			/>
			<path
				d="M210.22 142.149L210.26 142.049C210.88 140.389 211.84 138.869 213.09 137.609L213.57 137.119C213.8 136.879 214.17 136.829 214.46 136.989C214.83 137.199 214.96 137.679 214.73 138.039L214.35 138.639C213.96 139.259 214.44 140.049 215.17 139.989L216.22 139.909C216.41 139.889 216.6 139.889 216.79 139.889H220.09V140.009C220.09 140.589 219.94 141.149 219.65 141.649L219.85 142.479L212.52 144.559L210.22 142.149Z"
				fill="#A27F68"
			/>
			<path
				d="M167.89 212.219C170.43 201.299 170.03 198.249 168.95 195.809C167.16 191.769 163.58 189.559 161.36 188.459C160.58 189.839 159.49 191.959 158.49 194.659C156.66 199.589 156.32 203.239 155.63 207.859C155.11 211.319 154.24 216.299 152.75 222.379C150.92 226.479 149.08 231.349 147.55 236.919C145.75 243.459 144.81 249.419 144.33 254.469C146.45 254.469 148.58 254.469 150.71 254.459C152.73 251.619 154.81 248.339 156.78 244.619C160.42 237.759 162.67 231.309 164.11 225.929C164.85 223.589 165.87 220.159 166.97 215.949C167.26 214.829 167.56 213.629 167.89 212.219Z"
				fill="#6554C0"
			/>
			<path
				d="M176.58 191.57C179.52 198.15 181.75 203.76 183.34 207.95C186.95 217.46 187.57 220.51 187.81 221.82C188.78 227.05 188.69 231 188.5 237.33C188.37 241.83 187.97 247.87 186.83 255C184.86 255.11 182.88 255.22 180.91 255.33C179.04 250.46 177.13 243.9 176.47 235.99C176.11 231.71 176.19 227.83 176.47 224.47C173.58 221.68 170.54 218.48 167.48 214.83C165.05 211.93 162.9 209.11 161 206.41C161 206.41 156.64 199.14 158.38 195.02C158.38 195.01 158.39 194.99 158.4 194.98C158.89 193.79 159.33 192.79 159.55 192.3C160.25 190.75 160.84 189.5 161.25 188.66C163.04 189.3 165.07 189.9 167.32 190.4C170.78 191.17 173.92 191.48 176.58 191.57Z"
				fill="url(#paint17_linear_1375_267)"
			/>
			<path
				d="M172.03 148.42C171.56 148.18 171.05 147.95 170.5 147.72C169.91 147.47 169.34 147.26 168.81 147.09C168.59 146.7 167.07 144.07 163.95 143.52C163.46 143.44 161.77 143.16 160.05 144.06C157.21 145.55 156.37 149.16 156.64 151.67C156.74 152.59 156.91 154.28 158.16 155.58C159.93 157.42 162.29 156.85 162.98 158.45C163.55 159.76 162.18 160.72 162.73 161.65C163.73 163.34 169.99 163.03 171.08 160.08C171.23 159.68 171.48 158.71 170.76 157.19C171.68 155.93 172.61 154.67 173.53 153.42C173.3 152.89 172.96 152.24 172.45 151.55C171.97 150.91 171.49 150.41 171.07 150.05C171.23 149.86 171.41 149.63 171.58 149.35C171.79 149 171.93 148.68 172.03 148.42Z"
				fill="#A27F68"
			/>
			<path
				d="M163.94 151.95C163.79 150.89 164.25 150.56 163.88 149.91C163.46 149.16 162.68 149.28 162.15 148.38C161.99 148.12 161.68 147.58 161.84 147.09C162.25 145.82 165.44 146.23 165.81 145C165.91 144.65 165.76 144.29 165.61 144.04C165.08 143.65 164.2 143.12 163.02 142.93C160.36 142.5 157.52 144.01 156.11 146.43C154.47 149.25 155.11 152.73 156.74 154.54C157.37 155.24 158.63 156.65 160.4 156.92C160.96 157.01 161.84 157.02 162.34 157.67C162.73 158.17 162.66 158.75 162.98 158.84C163.06 158.86 163.36 158.91 164.01 158.08C164.71 157.19 165.56 156.12 165.39 154.83C165.22 153.5 164.16 153.48 163.94 151.95Z"
				fill="#42526E"
			/>
			<path
				d="M171.78 160.529C178.49 163.269 181.57 171.049 181.57 176.839C181.57 180.219 180.53 183.109 179.64 185.569C178.42 188.949 177.51 190.789 176 192.699C171.74 198.069 159.59 197.749 157.75 193.939C157.36 193.139 157.51 192.349 157.55 192.109C159.83 178.359 156.81 163.059 163.89 159.849C166.86 158.499 170.07 159.829 171.78 160.529Z"
				fill="#5243AA"
			/>
			<path
				d="M213.33 144.43C213.47 144.59 213.62 144.74 213.76 144.9C209.95 149.37 203.65 155.67 194.36 160.95C183.21 167.29 172.8 169.3 166.51 170.01C164.79 168.3 162.68 165.68 163.29 163.25C163.79 161.24 166.05 159.77 176.29 157.08C180.37 156.01 185.85 154.7 192.45 153.53C198.37 149.36 204.3 145.18 210.22 141.01C210.23 141.02 210.23 141.02 210.24 141.03L213.33 144.43Z"
				fill="url(#paint18_linear_1375_267)"
			/>
			<path
				d="M416.51 117.27L420.41 121.18L421.77 118.83L417.41 116.13C417.1 115.94 416.71 116 416.47 116.27C416.23 116.56 416.24 117 416.51 117.27Z"
				fill="#D4B696"
			/>
			<path
				d="M185.75 257.109C185.75 257.119 185.76 257.129 185.77 257.139C185.88 257.189 186.19 256.219 186.63 256.019C187.47 255.639 188.86 258.159 191.32 259.779C192.43 260.509 193.53 260.909 194.4 261.129C194.73 261.209 194.96 261.509 194.96 261.849V262.549H180.01C179.98 261.659 180.04 260.509 180.34 259.209C180.53 258.409 180.77 257.699 181.02 257.119H185.75V257.109Z"
				fill="#42526E"
			/>
			<path
				d="M180.08 259.818C181.72 259.818 183.05 261.138 183.09 262.768C182.06 262.768 181.03 262.768 180 262.768V259.818C180.03 259.828 180.06 259.818 180.08 259.818Z"
				fill="#B3BAC5"
			/>
			<path
				d="M148.77 257.109C148.77 257.119 148.78 257.129 148.79 257.139C148.9 257.189 149.21 256.219 149.65 256.019C150.49 255.639 151.88 258.159 154.34 259.779C155.45 260.509 156.55 260.909 157.42 261.129C157.75 261.209 157.98 261.509 157.98 261.849V262.549H143.03C143 261.659 143.06 260.509 143.36 259.209C143.55 258.409 143.79 257.699 144.04 257.119H148.77V257.109Z"
				fill="#42526E"
			/>
			<path
				d="M143.11 259.818C144.75 259.818 146.08 261.138 146.12 262.768C145.09 262.768 144.06 262.768 143.03 262.768V259.818C143.05 259.828 143.08 259.818 143.11 259.818Z"
				fill="#B3BAC5"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_1375_267"
				x1="141.994"
				y1="181.192"
				x2="0.870117"
				y2="181.192"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#C1C7D0" />
				<stop offset="1" stopColor="#C1C7D0" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1375_267"
				x1="208.292"
				y1="264.428"
				x2="0.870117"
				y2="264.428"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#091E42" />
				<stop offset="0.8433" stopColor="#091E42" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_1375_267"
				x1="357.327"
				y1="264.428"
				x2="564.748"
				y2="264.428"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#091E42" />
				<stop offset="0.8433" stopColor="#091E42" stopOpacity="0" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_1375_267"
				x1="423.624"
				y1="181.192"
				x2="564.748"
				y2="181.192"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#0065FF" />
				<stop offset="0.1724" stopColor="#076AFF" stopOpacity="0.8007" />
				<stop offset="0.4145" stopColor="#1976FF" stopOpacity="0.5208" />
				<stop offset="0.6966" stopColor="#378BFF" stopOpacity="0.1947" />
				<stop offset="0.8651" stopColor="#4C9AFF" stopOpacity="0" />
			</linearGradient>
			<radialGradient
				id="paint4_radial_1375_267"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(285 99.0229) scale(96.5182)"
			>
				<stop stopColor="#FFF0B2" />
				<stop offset="0.1584" stopColor="#FFE587" stopOpacity="0.8416" />
				<stop offset="0.3589" stopColor="#FFDA57" stopOpacity="0.6411" />
				<stop offset="0.5498" stopColor="#FFD031" stopOpacity="0.4502" />
				<stop offset="0.7256" stopColor="#FFC916" stopOpacity="0.2744" />
				<stop offset="0.8811" stopColor="#FFC506" stopOpacity="0.1189" />
				<stop offset="1" stopColor="#FFC400" stopOpacity="0" />
			</radialGradient>
			<linearGradient
				id="paint5_linear_1375_267"
				x1="346.074"
				y1="98.8386"
				x2="423.624"
				y2="98.8386"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#0052CC" />
				<stop offset="1" stopColor="#2684FF" />
			</linearGradient>
			<linearGradient
				id="paint6_linear_1375_267"
				x1="237.087"
				y1="120.044"
				x2="276.412"
				y2="120.044"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7A869A" />
				<stop offset="1" stopColor="#DFE1E5" />
			</linearGradient>
			<linearGradient
				id="paint7_linear_1375_267"
				x1="237.087"
				y1="75.0106"
				x2="276.412"
				y2="75.0106"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7A869A" />
				<stop offset="1" stopColor="#DFE1E5" />
			</linearGradient>
			<linearGradient
				id="paint8_linear_1375_267"
				x1="335.969"
				y1="146.593"
				x2="335.969"
				y2="51.4514"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#2684FF" />
				<stop offset="1" stopColor="#B2D4FF" />
			</linearGradient>
			<linearGradient
				id="paint9_linear_1375_267"
				x1="219.544"
				y1="98.8386"
				x2="141.994"
				y2="98.8386"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7A869A" />
				<stop offset="1" stopColor="#EBECF0" />
			</linearGradient>
			<linearGradient
				id="paint10_linear_1375_267"
				x1="229.65"
				y1="146.593"
				x2="229.65"
				y2="51.4514"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#C1C7D0" />
				<stop offset="1" stopColor="#F4F5F7" />
			</linearGradient>
			<linearGradient
				id="paint11_linear_1375_267"
				x1="402.858"
				y1="216.64"
				x2="421.684"
				y2="223.492"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#4C9AFF" />
				<stop offset="0.8134" stopColor="#0065FF" />
				<stop offset="0.8713" stopColor="#0065FF" />
			</linearGradient>
			<linearGradient
				id="paint12_linear_1375_267"
				x1="376.968"
				y1="157.139"
				x2="433.936"
				y2="157.139"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#B2D4FF" />
				<stop offset="1" stopColor="#2684FF" />
			</linearGradient>
			<linearGradient
				id="paint13_linear_1375_267"
				x1="289.638"
				y1="45.0027"
				x2="289.638"
				y2="0.510323"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FFF0B2" />
				<stop offset="1" stopColor="#FFC400" />
			</linearGradient>
			<linearGradient
				id="paint14_linear_1375_267"
				x1="262.238"
				y1="36.2259"
				x2="272.592"
				y2="36.2259"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FFC400" />
				<stop offset="1" stopColor="#FFF0B2" />
			</linearGradient>
			<linearGradient
				id="paint15_linear_1375_267"
				x1="259.6"
				y1="180.859"
				x2="259.6"
				y2="141.017"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FFC400" />
				<stop offset="1" stopColor="#FFF0B2" />
			</linearGradient>
			<linearGradient
				id="paint16_linear_1375_267"
				x1="284.971"
				y1="171.613"
				x2="284.971"
				y2="149.45"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FFC400" />
				<stop offset="1" stopColor="#FFF0B2" />
			</linearGradient>
			<linearGradient
				id="paint17_linear_1375_267"
				x1="157.982"
				y1="221.995"
				x2="188.627"
				y2="221.995"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.3435" stopColor="#6554C0" />
				<stop offset="1" stopColor="#998DD9" />
			</linearGradient>
			<linearGradient
				id="paint18_linear_1375_267"
				x1="163.185"
				y1="155.517"
				x2="213.758"
				y2="155.517"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#5243AA" />
				<stop offset="1" stopColor="#8777D9" />
			</linearGradient>
			<clipPath id="clip0_1375_267">
				<rect
					width="563.88"
					height="265.81"
					fill="white"
					transform="translate(0.870117 0.509766)"
				/>
			</clipPath>
		</defs>
	</svg>
);
